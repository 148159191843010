const siteDirectory = {
  specialties: [
    {
      name: 'Obsessive-Compulsive Disorder',
      route: '/specialties/obsessive-compulsive-disorder',
    },
    {
      name: 'Scrupulosity OCD',
      route: '/specialties/scrupulosity-ocd',
    },
    {
      name: 'Anxiety Treatment',
      route: '/specialties/anxiety-treatment',
    },
    {
      name: 'Generalized Anxiety Disorder',
      route: '/specialties/general-anxiety-disorder',
    },
    {
      name: 'Mindfulness Training',
      route: '/specialties/mindfulness',
    },

    {
      name: 'Panic',
      route: '/specialties/panic',
    },
    {
      name: 'Social Phobia',
      route: '/specialties/social-phobia',
    },

    {
      name: 'Adolescents',
      route: '/relationships/adolescents',
    },
    {
      name: 'OCD in Children',
      route: '/relationships/ocd-in-children',
    },
    // {
    //   name: 'Extended Outpatient Program',
    //   route: '/specialties/extended-outpatient-program',
    // },
    // {
    //   name: 'Tics and Tourettes',
    //   route: '/specialties/tics-and-tourettes',
    // },
    // {
    //   name: 'Body Dysmorphic Disorder',
    //   route: '/specialties/body-dysmorphic-disorder',
    // },
    // {
    //   name: 'Trichotillomania',
    //   route: '/specialties/trichotillomania',
    // },
    {
      name: 'Depression',
      route: '/specialties/depression',
    },
    {
      name: 'Trauma',
      route: '/specialties/trauma',
    },
    {
      name: 'Perfectionism',
      route: '/specialties/perfectionism',
    },
  ],
  about: [
    {
      name: 'Why Mindset Family Therapy?',
      route: '/philosophy',
    },
    {
      name: 'Our Team',
      route: '/team',
    },
    {
      name: 'Online Psychotherapy',
      route: '/online-psychotherapy',
    },
    {
      name: 'Testimonials',
      route: '/testimonials',
    },
    {
      name: 'Frequently Asked Questions',
      route: '/frequently-asked-questions',
    },
    {
      name: 'Groups',
      route: '/groups',
    },
    {
      name: 'Products',
      route: '/products',
    },
    {
      name: 'Books',
      route: '/books',
    },
    {
      name: 'Press',
      route: '/press',
    },
  ],
  books: [
    {
      name: 'Imperfectly Good',
      route: '/books/imperfectly-good',
    },
    {
      name: 'The Masterpiece Mindset',
      route: '/books/the-masterpiece-mindset',
    },
    {
      name: 'Let Go of Anxiety',
      route: '/books/let-go-of-anxiety',
    },
    {
      name: 'Free Downloads',
      route: '/books/downloads',
    },
  ],
};

export default siteDirectory;

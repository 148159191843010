import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import AboutDirectory from '../components/AboutDirectory';

export default () => (
  <React.Fragment>
    <SEO title="About | Mindset Family Therapy" pathname="/about" />
    <Layout>
      <AboutDirectory />
    </Layout>
  </React.Fragment>
);

import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import PageHeader from '../PageHeader';
import data from '../../data/siteDirectory';

import styles from './aboutDirectory.module.scss';

const AboutDirectory = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';

  const about = _.map(data.about, (aboutView, index) => {
    return (
      <li key={index}>
        <span>
          <Link to={aboutView.route}>{aboutView.name}</Link>
        </span>
      </li>
    );
  });

  return (
    <main>
      <PageHeader pageName="About" headerImage={background} />
      <div className={styles.textContainer}>
        <h2 className={styles.header}>About Mindset Family Therapy</h2>
        <ul>{about}</ul>
      </div>
    </main>
  );
};

export default AboutDirectory;
